import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// Contexts
import { LoaderProvider } from "./context/LoaderContext";
import { AuthProvider, AuthContext } from "./context/AuthContext";

// Components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import DownloadApp from "./components/Download/DownloadApp";

// Public Pages (First File)
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
import TermAndCondition from "./pages/terms/TermAndCondition";

// Public Pages (Second File)
import Login from "./pages/login/Login";
import SignUp from "./pages/signup/SignUp";
import ForgotPass from "./pages/forgotPass/ForgotPass";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ResendLinkSent from "./pages/resendLinkSentSuccessfully/ResendLinkSent";
import Validate from "./pages/validate/Validate";

// Private Pages
import Dashboard from "./pages/dashboard/Dashboard";
import Transaction from "./pages/Transaction/Transaction";
import MyDeepLink from "./pages/myDeepLink/MyDeepLink";
import ChangePassword from "./pages/changePassword/ChangePassword";
import PaymentSuccess from "./pages/paymentSuccess/PaymentSuccess";

// Routes
import PrivateRoutes from "./Routes/PrivateRoutes";

const App = () => (
  <AuthProvider>
    <LoaderProvider>
      <Router>
        <ConditionalLayout>
          <AppRoutes />
        </ConditionalLayout>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
        />
      </Router>
    </LoaderProvider>
  </AuthProvider>
);

const AppRoutes = () => {
  const { isAuthenticated } = useContext(AuthContext);

  console.log("inside Approute==>", isAuthenticated);

  return (
    <Routes>
      {/* Always visible routes */}
      <Route path="/" element={<Home />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/termandcondition" element={<TermAndCondition />} />

      {isAuthenticated ? (
        <>
          {/* Private Routes */}
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/myDeepLink" element={<MyDeepLink />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/paymentSuccess" element={<PaymentSuccess />} />
          </Route>
          {/* Redirect unmatched routes to dashboard */}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </>
      ) : (
        <>
          {/* Public routes for unauthenticated users */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPass />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route
            path="/resend-link-successfully"
            element={<ResendLinkSent />}
          />
          <Route path="/validate" element={<Validate />} />
          {/* Redirect unmatched routes to login */}
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
    </Routes>
  );
};

const ConditionalLayout = ({ children }) => {
  const location = useLocation();

  // Define routes where Header, Footer, and DownloadApp should be displayed
  const fullLayoutRoutes = ["/", "/privacy", "/termandcondition"];

  // Check if current route is in the full layout routes
  const showFullLayout = fullLayoutRoutes.includes(location.pathname);

  return (
    <>
      {showFullLayout && <Header />}
      {children}
      {showFullLayout && <DownloadApp />}
      {showFullLayout && <Footer />}
    </>
  );
};

export default App;
