import React from "react";
import "./loginLayout.css"; // CSS file for styling

import logo from "../../assets/gif.png"; // Adjust the path as per your project structure

const LoginLayout = ({ children }) => {
  return (
    <div className="login-layout">
      <div className="login-left">
        {/* Optional: Sidebar or left-side content */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          {/* <img src={logo} alt="logo" className="gif" /> */}
          <div className="video-container" style={{ width: "60%" }}>
            <video
              width="100%"
              height="auto"
              controls
              autoPlay
              muted
              loop
              class="my-video"
              onContextMenu={(e) => e.preventDefault()} // Disables right-click context menu
              controlsList="nodownload" // Tries to disable download in some browsers
            >
              <source
                src="https://dwcx2tp3n4yrr.cloudfront.net/shared/Virtual+Intros-3.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <p style={{ fontSize: "16px", margin: "0px" }}>
            Make Virtual Intros Look Like a Built-In Feature of Your Event App
          </p>
          <hr style={{ width: "100%", margin: "0.6 rem" }} />
          <div style={{ display: "flex", gap: "10px" }}>
            <h1 className="benefit">Benefit</h1>
            <p className="benefitText" style={{ lineHeight: "1.1" }}>
              Add the AI-powered networking features of Virtual Intros to your
              existing event app in a way that feels seamless and natural. With
              our deep link technology, Virtual Intros works like it’s a part of
              your app—not a separate app—providing attendees with a simple and
              connected experience.
            </p>
          </div>
          <div className="value-cards-layout">
            <div
              className="value-card-layout value-card-1-layout"
              style={{ padding: "10px" }}
            >
              <h4>01. Capability</h4>
              <ul>
                <li>
                  Offer AI-driven, location-based, confidential networking
                  directly within your event app.
                </li>
                <li>
                  Add Virtual Intros to as many events as you want within a
                  timeframe—attendees can even start connecting virtually before
                  the event begins.
                </li>
                <li>
                  Integrate Virtual Intros as a feature of your app, with
                  networking access continuing after the event.
                </li>
                <li>
                  Keep your app’s branding consistent while enhancing attendee
                  engagement with cutting-edge AI capabilities.
                </li>
              </ul>
            </div>
            <div
              className="value-card-layout value-card-2-layout"
              style={{ padding: "10px" }}
            >
              <h4>02. Features</h4>
              <ul>
                <li>
                  Offer AI-driven, location-based, confidential networking
                  directly within your event app.
                </li>
                <li>
                  Add Virtual Intros to as many events as you want within a
                  timeframe—attendees can even start connecting virtually before
                  the event begins.
                </li>
                <li>
                  Integrate Virtual Intros as a feature of your app, with
                  networking access continuing after the event.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <main className="login-right">
        {children} {/* Render child components */}
      </main>
    </div>
  );
};

export default LoginLayout;
