import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import "../assets/scss/_custom-variables.scss";

const HeaderTableCell = styled(TableCell)(({ width }) => ({
  fontSize: "16px",
  fontWeight: "bold",
  whiteSpace: "normal",
  wordBreak: "break-word",
  width: width || "auto",
}));

const RowTableCell = styled(TableCell)(({ width }) => ({
  fontSize: "14px",
  whiteSpace: "normal",
  wordBreak: "break-all",
  width: width || "auto",
  fontFamily: '"Poppins", sans-serif',
  verticalAlign: "baseline",
}));

const CustomTable = ({
  columns,
  data,
  renderAction,
  page,
  onPageChange,
  rowsPerPage,
  totalCount,
}) => {
  const handleChangePage = (event, newPage) => {
    onPageChange(newPage);
  };

  return (
    <Paper>
      <TableContainer>
        <Table sx={{ tableLayout: "fixed", height: `calc(8 * 53px + 56px)` }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <HeaderTableCell
                  key={column.id}
                  width={column.width}
                  sx={{
                    textAlign: "center",
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  {column.label}
                </HeaderTableCell>
              ))}
              {renderAction && (
                <HeaderTableCell
                  width="100px"
                  sx={{
                    textAlign: "center",
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  Actions
                </HeaderTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <RowTableCell
                      key={column.id}
                      width={column.width}
                      sx={{
                        textAlign: "center",
                        fontFamily: '"Poppins", sans-serif',
                      }}
                    >
                      {row[column.id]}
                    </RowTableCell>
                  ))}
                  {renderAction && (
                    <RowTableCell
                      width="100px"
                      sx={{
                        textAlign: "center",
                        fontFamily: '"Poppins", sans-serif',
                      }}
                    >
                      {renderAction(row)}
                    </RowTableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length + (renderAction ? 1 : 0)}
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontStyle: "italic",
                    padding: "20px",
                    height: `calc(8 * 53px)`, // Vertically center the message
                  }}
                >
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        sx={{
          fontSize: "14px",
          ".MuiTablePagination-displayedRows": {
            fontSize: "14px",
            margin: "0px",
          },
        }}
      />
    </Paper>
  );
};

export default CustomTable;
