import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ background, width }) => ({
  background: background,
  color: '#fff',
  height: '55px',
  fontWeight: '600',
  fontSize: '18px',
  fontFamily: 'var(--font-poppins)',
  borderRadius: '30px',
  lineHeight: '27px',
  width: width,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'none',
  '&:hover': {
    opacity: 0.8,
  },
}));

const CustomButton = ({ text, disabled = false, background, onClick, type = 'button', width, className, icon }) => {
  return (
    <StyledButton
      background={background}
      onClick={onClick}
      variant="contained"
      type={type}
      disabled={disabled}
      width={width}
      className={className}
      startIcon={icon}
    >
      {text}
    </StyledButton>
  );
};

export default CustomButton;
