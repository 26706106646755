import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import CustomButton from "../CustomButton";

const IsVerifiedModal = ({ open, onResend }) => {
  return (
    <Dialog
      open={open}
      disableEscapeKeyDown // Prevent closing on pressing the 'Escape' key
      onClose={() => {}} // No-op function to prevent closing on backdrop click
      sx={{ textAlign: "center", justifyContent: "center" }}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(255, 255, 255, 0)", // Set proper opacity
          backdropFilter: "blur(9px)", // Apply blur effect to backdrop
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: "15px", // Rounded corners for the modal
          padding: "20px", // Padding around the modal
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "22px",
          fontWeight: "600",
          lineHeight: "26.82px",
          letterSpacing: "-0.02rem",
          fontFamily: "var(--font-montserrat)",
        }}
      >
        Verification Needed
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "20px",
            fontFamily: "var(--font-montserrat)",
          }}
        >
          Your account needs verification. Would you like to resend the
          verification email?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <CustomButton
          text="Resend"
          width="250px"
          background="#355935"
          onClick={onResend}
        />
      </DialogActions>
    </Dialog>
  );
};

export default IsVerifiedModal;
