import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../components/CustomTextField";
import DasboardLayout from "../../helper/dashboardLayout/Dasboardlayout";
import CustomButton from "../../components/CustomButton";
// import axios from 'axios';
import { notifyError, notifySuccess } from "../../utils/toast";
import { useLoader } from "../../context/LoaderContext";
import IsVerifiedModal from "../../components/modal/IsVerifiedModal";
import axiosInstance from "../../axiosConfig";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  appName: Yup.string().required("Required"),
  duration: Yup.string().required("Required"),
});

const Dashboard = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    handleFetchUser();
  }, []);

  const handleFetchUser = async () => {
    const timezoneOffset = -new Date().getTimezoneOffset();
    const token = localStorage.getItem("accessToken");

    try {
      showLoader();

      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}`,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            version: "1.0",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      localStorage.setItem("isVerified", response.data?.data.is_verify);
      localStorage.setItem("webUserEmail", response.data?.data.email);
      localStorage.setItem("webUserId", response.data?.data.web_user_id);

      const isVerified = response.data?.data.is_verify === 1;
      setModalOpen(!isVerified); // Open modal if not verified
      hideLoader();
    } catch (error) {
      console.error("Error during sign-up:", error);
      hideLoader();
      // You can also access error.response for more details if needed
      notifyError(error.response.data.message);
    }
  };

  const handleSubmit = async (values) => {
    // console.log(values)
    const payload = {
      // name: values.name,
      appName: values.appName,
      price: values.price,
      duration: values.duration,
      name: values.name,
    };

    const timezoneOffset = -new Date().getTimezoneOffset();
    const token = localStorage.getItem("accessToken");

    try {
      // Make the POST request with Axios
      showLoader();
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/deeplink-token`,
        payload,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            version: "1.0",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // navigate('/Mydeeplink')
      const res = response.data?.data;
      sessionStorage.setItem("deeplink", res.deeplink);
      console.log("response===>dash", res);

      const payload2 = {
        price_id: res.price_id,
        deeplink_id: res.id,
      };

      const payRes = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/deeplink-payment`,
        payload2,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            version: "1.0",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("payRes.data==>", payRes.data?.data.url);

      const payment = payRes.data?.data.url;

      window.location.href = payment;

      hideLoader();
    } catch (error) {
      console.error("", error);
      hideLoader();
      // You can also access error.response for more details if needed
      notifyError(error.response.data.message);
    }
  };

  const handleResend = async () => {
    const payload = {
      // name: values.name,
      user_id: localStorage.getItem("webUserId"),
    };

    const timezoneOffset = -new Date().getTimezoneOffset();
    const token = localStorage.getItem("accessToken");

    try {
      // Make the POST request with Axios
      showLoader();
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/resend-verification-link`,
        payload,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            version: "1.0",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // navigate('/Mydeeplink')
      const res = response.data?.message;
      notifySuccess(res);
      hideLoader();
    } catch (error) {}
  };
  return (
    <DasboardLayout>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "50%" }}>
          <Formik
            initialValues={{
              name: "",
              appName: "",
              duration: "",
              price: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Field
                  name="name"
                  label="Name"
                  component={CustomTextField}
                  placeholder="Enter your name"
                />
                <Field
                  name="appName"
                  label="App Name"
                  component={CustomTextField}
                  placeholder="Enter your app name"
                />
                <Field
                  name="duration"
                  label="Duration"
                  component={CustomTextField}
                  isSelect // Set this to use the select input
                  options={[
                    { label: "6 months", value: "6" },
                    { label: "12 months + 1 month free", value: "12" },
                  ]}
                  placeholder="Select duration"
                  onChange={(event) => {
                    const duration = event.target.value;
                    setFieldValue("duration", duration);
                    setFieldValue("price", duration === "6" ? "999" : "1899");
                  }}
                />
                <Field
                  name="price"
                  label="Price(USD)"
                  component={CustomTextField}
                  placeholder="Price"
                  disabled
                  InputProps={{ readOnly: true }} // Make the price field read-only
                />
                {/* <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
            Submit
          </Button> */}
                <div
                  // className={styles.loginButtonContainer}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <CustomButton
                    text="Submit"
                    background="#355935"
                    type="submit"
                    // className={styles.loginSubmit}
                    width={200}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {modalOpen && (
        <IsVerifiedModal open={modalOpen} onResend={handleResend} />
      )}
    </DasboardLayout>
  );
};

export default Dashboard;
