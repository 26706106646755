import React from "react";
// import Header from "../header";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/headerDeeplink/Header";

const DasboardLayout = (props) => {
  return (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <div className="content-wrapper">{props.children}</div>
    </div>
  );
};

export default DasboardLayout;
