import React from "react";
import HeroSection from "../components/HeroSection/HeroSection";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import ValueSection from "../components/ValueSection/ValueSection";
import AboutSection from "../components/AboutSection/AboutSection";
import Working from "../components/Working/Working";

function Home() {
  return (
    <div className="wrapper">
      {/* <Header /> */}
      <HeroSection />
      <HowItWorks />
      <Working />
      <ValueSection />
      <AboutSection />
      {/* <DownloadApp />
      <Footer /> */}
    </div>
  );
}

export default Home;
