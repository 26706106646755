import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleNavigation = (path, hash) => {
    navigate(path);
    setTimeout(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <footer className="footer-wrapper">
      <div className="App">
        <div className="footer">
          <nav>
            <div className="left-footer">
              <a
                onClick={() => handleNavigation("/")}
                style={{ cursor: "pointer" }}
              >
                {t("home")}
              </a>
              <a
                onClick={() => handleNavigation("/", "#about")}
                style={{ cursor: "pointer" }}
              >
                {t("about_us")}
              </a>
              <a
                onClick={() => handleNavigation("/", "#how-it-works")}
                style={{ cursor: "pointer" }}
              >
                {t("how_it_works")}
              </a>
              <a
                onClick={() => handleNavigation("/", "#contact")}
                style={{ cursor: "pointer" }}
              >
                {t("contact_us")}
              </a>
              {/* <a
                onClick={() => handleNavigation("/login")}
                style={{ cursor: "pointer" }}
              >
                {t("services")}
              </a> */}
            </div>
            <div className="right-footer">
              <a
                onClick={() => handleNavigation("/termandcondition")}
                style={{ cursor: "pointer" }}
              >
                {t("terms_conditions")}
              </a>
              <a
                onClick={() => handleNavigation("/privacy")}
                style={{ cursor: "pointer" }}
              >
                {t("privacy_policy")}
              </a>
            </div>
          </nav>
        </div>
      </div>
      <div className="copyright">
        <p>{t("copyright")}</p>
      </div>
    </footer>
  );
};

export default Footer;
