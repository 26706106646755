import { useContext, useEffect } from "react";
import { Dropdown, Image } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { clearAuth } from "../../Redux/slice/authSlice";
import logoImg from "../../assets/images/Logo-Phthalo.svg";
import hamburgerImage from "../../assets/images/hamburger.svg";
import backImage from "../../assets/images/backImage.svg";
import { useState } from "react";
import LogoutModal from "../modal/LogoutModal";
import { notifyError } from "../../utils/toast";
import axiosInstance from "../../axiosConfig";
import { AuthContext } from "../../context/AuthContext";
// import { useLogoutMutation } from "../../Redux/api/authApi";

const Header = ({
  hamburger = true,
  brandLogo = false,
  noMarginLeft = false,
}) => {
  //   const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAuthenticated } = useContext(AuthContext);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLogoutmodal, setLogoutmodalOpen] = useState(false);

  //   const deviceId = useSelector((state) => state.auth.deviceId);
  //   const email = useSelector((state) => state.auth.user.email);

  const email = localStorage.getItem("webUserEmail");
  const [pageTitle, setPageTitle] = useState("Users"); // Default title
  const [isMainRoute, setIsMainRoute] = useState(true);
  const toggleMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);

    // Toggle body class
    document.body.classList.toggle("sidebar-toggle");
  };

  const toggleOff = () => {
    setIsSidebarOpen(true);

    // Toggle body class
    document.body.classList.toggle("sidebar-toggle");
  };

  const hideSidebar = () => {
    setIsSidebarOpen(false);
    // Remove body class
    document.body.classList.remove("sidebar-toggle");
  };

  //   const handleLogout = () => {
  //     console.log('logout')

  //   };

  const handleLogout = async (values) => {
    // Define the payload based on the form values
    const payload = {};

    const timezoneOffset = -new Date().getTimezoneOffset();
    const token = localStorage.getItem("accessToken");

    try {
      // Make the POST request with Axios
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        payload,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            version: "1.0",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      localStorage.clear();
      setIsAuthenticated(false);
      navigate("/login");
    } catch (error) {
      console.error("Error during sign-up:", error);
      // You can also access error.response for more details if needed
      notifyError(error.response.data.message);
    }
  };

  //   const [
  //     submit,
  //     {
  //       data: submitData,
  //       isSuccess: submitSuccess,
  //       isLoading: submitLoading,
  //       reset: submitReset,
  //       isError: submitError,
  //     },
  //   ] = useLogoutMutation();
  //   const handleLogoutApi = async () => {
  //     await submit({
  //       deviceType: 3,
  //       deviceId: deviceId,
  //     });
  //   };
  // useEffect(() => {
  //   if (submitSuccess) {
  //     dispatch(clearAuth());
  //     localStorage.clear();
  //     submitReset();
  //     navigate(`/`);
  //   }
  // }, [submitSuccess]);

  useState(() => {
    if (!isSidebarOpen) {
      document.body.classList.remove("sidebar-toggle");
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    const currentPath = location.pathname.toLowerCase(); // Normalize the path to lowercase
    console.log("current path==>", currentPath);

    // Update the title based on the current route
    if (currentPath.includes("/dashboard")) {
      setPageTitle("Virtual intros deep links for your company");
    } else if (currentPath.includes("/change-password")) {
      setPageTitle("Change Password");
    } else if (currentPath.includes("/transaction")) {
      setPageTitle("Transaction History");
    } else if (currentPath.includes("/mydeeplink")) {
      setPageTitle("My Deep Links");
    } else if (currentPath.includes("/paymentsuccess")) {
      setPageTitle("Payment Successful");
    } else {
      setPageTitle("Page");
    }
  }, [location.pathname]); // Ensure this effect runs whenever location.pathname changes

  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase(); // Normalize path to lowercase
    console.log("currentLocation===>", currentLocation);

    // Check for main route paths
    if (
      currentLocation === "/transaction" ||
      currentLocation === "/mydeeplink" ||
      currentLocation === "/dashboard" ||
      currentLocation === "/paymentsuccess"
    ) {
      setIsMainRoute(true);
    } else {
      setIsMainRoute(false);
      toggleOff();
    }
  }, [location.pathname]); // Ensure this effect runs when location.pathname changes

  console.log(isSidebarOpen);

  const backImageClick = () => {
    navigate(-1);
  };

  const handleCancelLogoutModal = () => {
    setLogoutmodalOpen(false);
  };

  return (
    <>
      <header className={`main-header d-flex ${noMarginLeft ? "ms-0" : ""}`}>
        <nav className="navbar navbar-expand flex-one">
          {isMainRoute && hamburger && (
            <div className="burger-menu" role="menu" onClick={toggleMenu}>
              <Image
                src={hamburgerImage}
                alt="auth bg-main"
                className="image-fit auth-bg"
              />
            </div>
          )}
          {!isMainRoute && (
            <div className="burger-menu" role="menu" onClick={backImageClick}>
              <Image
                src={backImage}
                alt="auth bg"
                className="image-fit auth-bg"
              />
            </div>
          )}
          <div style={{ paddingLeft: "5px" }}>
            <span className="fw-500 pageHeading">{pageTitle}</span>
          </div>
          {brandLogo && (
            <div className="brand-link">
              <Image
                src={logoImg}
                alt="auth bg"
                className="image-fit auth-bg"
              />
            </div>
          )}

          <div className="collapse navbar-collapse">
            <div className="navbar-nav ms-auto justify-content-center align-items-center">
              <div className="text-left d-flex user-profile ">
                <div>
                  {/* <span className="fs-6">{email}</span> */}
                  <span className="fs-6">{email}</span>
                </div>
              </div>

              <div>
                <ul style={{ marginTop: "9px" }}>
                  <li className="nav-item">
                    <Dropdown className="custom-dropdown">
                      <Dropdown.Toggle
                        as="button"
                        className="nav-link d-flex align-items-center"
                      ></Dropdown.Toggle>
                      <Dropdown.Menu as="ul">
                        {/* <li><Link className='dropdown-item'>Profile</Link></li> */}
                        <li className="ps-3 pe-3">
                          <Link
                            to="/change-password"
                            className={
                              window.location.pathname.includes(
                                "change-password"
                              )
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                          >
                            Change Password
                          </Link>
                        </li>
                        <li className="ps-3 pe-3">
                          {" "}
                          <Dropdown.Item
                            as="button"
                            onClick={() => setLogoutmodalOpen(true)}
                          >
                            Logout
                          </Dropdown.Item>
                        </li>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>

            <div className="burger-menu ml-3 burger-menu-mobile" role="menu">
              <div className="burger"></div>
            </div>
          </div>
        </nav>
      </header>
      <LogoutModal
        open={isLogoutmodal}
        onConfirm={handleLogout}
        onCancel={handleCancelLogoutModal}
      />
      {isSidebarOpen && (
        <div className="sidebar-overlay" onClick={hideSidebar}></div>
      )}
    </>
  );
};

export default Header;
