// src/Routes/PrivateRoutes.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  // Example authentication check (replace with actual auth logic)
  const isAuthenticated = Boolean(localStorage.getItem("isAuthenticated"));

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
