import React from "react";
import "./AboutSection.css";
import AboutImage from "../../assets/aboutsection.png";
import ProfileImage from "../../assets/profile-image.svg"; // Assuming you have a profile image
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <section className="about-section-wrapper">
        <div className="App">
          <div id="about" className="about-section">
            <div className="image-section">
              <img src={AboutImage} alt="about-image" />
            </div>
            <div className="content-section">
              <h2>{t('about_title')}</h2>
              <h3>{t('virtual_intros')}</h3>
              <h5>{t('welcome_to_laigic')}</h5>
              <p>
                {t('about_description')}
              </p>
              <div className="testimonial">
                <img src={ProfileImage} alt="Jair Clarke" />
                <div className="testimonial-content">{t('testimonial')}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
