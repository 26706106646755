import React from "react";
import "./DownloadApp.css";
import DownloadLogo from "../../assets/download-logo.svg";
import play_logo from "../../assets/Mobile_play_store.svg";
import apple_logo from "../../assets/Mobile_app_store.svg";
import logo from "../../assets/attherate.svg";
import { useTranslation } from "react-i18next";

const DownloadApp = () => {
  const { t } = useTranslation();
  return (
    <section id="contact">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="hero-content"
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 className="ContactUsText">{t("contact_us")}</h2>
          <div style={{ display: "flex", gap: "10px" }}>
            <img src={logo} alt="img" className="attherate" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="ContactUsTextEmailText">{t("email")}</div>
              <div className="ContactUsTextEmail">
                customercare@virtualintros.com
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="App">
        <div className="download-section">
          <img src={DownloadLogo} alt="Download logo" />
          <h2>
            <b>{t("download_our_app")}</b>
          </h2>
          {/* <div className="hero-buttons_1">
            <button className="hero-apple-button_1">
              <img src={appleImage} alt="Apple icon_1" />
              <div className="button-text_1">
                <span>Download on the</span>
                <span style={{fontSize:"18px"}}>App Store</span>
              </div>
            </button>
            <button className="hero-play-button_1">
              <img src={playImage} alt="Play icon_1" />
              <div className="button-text_1">
                <span>Get it on</span>
                <span style={{fontSize:"18px"}}>Play Store</span>
              </div>
            </button>
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              cursor: "pointer",
            }}
          >
            <img
              className="Download-play"
              src={play_logo}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.intros.virtual&pli=1",
                  "_blank"
                )
              }
              alt="play store logo"
            />
            <img
              className="Download-play"
              src={apple_logo}
              onClick={() =>
                window.open(
                  "https://apps.apple.com/in/app/virtual-intros/id6621269996",
                  "_blank"
                )
              }
              alt="App store logo"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
