import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, FormHelperText } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "30px",
    fontSize: "16px",
    fontWeight: "500",
    height: "61px",
    border: "2px solid black",
    "& .MuiInputBase-input": {
      padding: "0px 0px 0px 20px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "1.5rem", // Adjust font size for error message
  },
}));

const CustomTextField = ({
  field,
  form,
  label,
  placeholder,
  type,
  isSelect = false,
  options = [],
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleSelectChange = (event) => {
    form.setFieldValue(field.name, event.target.value);
    form.setFieldTouched(field.name, false, true); // Clear error as soon as selection is made
  };

  const errorMessage =
    form.touched[field.name] && form.errors[field.name]
      ? String(form.errors[field.name])
      : "";

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {label && (
        <label
          style={{
            lineHeight: "20px",
            fontSize: "16px",
            fontWeight: "500",
            marginBottom: "8px",
            marginTop: "10px",
          }}
        >
          {label}
        </label>
      )}
      {isSelect ? (
        <FormControl
          error={Boolean(form.errors[field.name] && form.touched[field.name])}
          sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
        >
          <Select
            value={field.value || ""}
            onChange={handleSelectChange}
            displayEmpty
            sx={{
              borderRadius: "30px", // Add border-radius
              border: "2px solid black", // Custom border
              fontSize: "16px", // Font size for the selected value
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              "& .MuiSvgIcon-root": {
                fontSize: "2.5rem", // Adjust icon font size
              },
            }}
            {...props}
          >
            <MenuItem
              value=""
              disabled
              sx={{
                fontSize: "1.5rem", // Custom font size for placeholder
                fontWeight: "bold", // Optional: make placeholder bold
                color: "black", // Placeholder text color
                opacity: 0.6, // Optional: adjust opacity
              }}
            >
              {placeholder}
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  fontSize: "1.5rem",
                  padding: "10px 20px",
                  "&.Mui-selected": {
                    backgroundColor: "transparent", // Removes the selected background color
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Optional: customize hover for selected item
                  },
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {errorMessage && (
            <FormHelperText sx={{ fontSize: "1.2rem" }}>
              {errorMessage}
            </FormHelperText>
          )}
        </FormControl>
      ) : (
        <StyledTextField
          {...field}
          {...props}
          type={type === "password" && showPassword ? "text" : type}
          placeholder={placeholder}
          InputProps={{
            endAdornment: type === "password" && (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={handleClickShowPassword}
                  sx={{ fontSize: "2rem" }} // Increase icon size
                >
                  {showPassword ? (
                    <VisibilityOff fontSize="inherit" />
                  ) : (
                    <Visibility fontSize="inherit" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(form.errors[field.name] && form.touched[field.name])}
          helperText={errorMessage}
        />
      )}
    </div>
  );
};

export default CustomTextField;
