import React from "react";
import "./HowItWorks.css";
import { useTranslation } from "react-i18next";
// Removed unused import of Rectangle

const HowItWorks = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="how-it-works-wrapper">
        <div className="App">
          <section id="how-it-works" className="how_it_works">
            <div>
              <h2 className="how-it-works-1">{t("how_the_app_works")}</h2>
            </div>
          </section>
          <div className="how-it-works-rectangle">
            {/* Replacing the image with the video */}
            <video
              width="100%"
              height="auto"
              controls
              autoPlay
              muted
              loop
              onContextMenu={(e) => e.preventDefault()} // Disables right-click context menu
              controlsList="nodownload" // Tries to disable download in some browsers
            >
              <source
                src="https://d3nw6jtllu5stf.cloudfront.net/logo/Virtual%20Intros%20-%20Final%20Promo%20Video.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
