import React, { useEffect, useState } from "react";
import { notifyError } from "../../utils/toast";
import { useLoader } from "../../context/LoaderContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Validate = () => {
  const [token, setToken] = useState(null);
  const { showLoader, hideLoader } = useLoader();
  const [tokenExp, setTokenExp] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromURL = urlParams.get("token");
    setToken(tokenFromURL);

    const handleSubmit = async () => {
      const payload = {
        deeplinkToken: tokenFromURL,
      };

      const timezoneOffset = -new Date().getTimezoneOffset();

      try {
        showLoader();
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/validate-deeplink`,
          payload,
          {
            headers: {
              "client-secret": process.env.REACT_APP_CLIENT_SECRET,
              lang: "en",
              "timezone-offset": timezoneOffset,
              version: "1.0",
            },
          }
        );

        console.log("====>", response);

        const deeplink = response.data?.data.deeplink;

        if (deeplink) {
          // Redirect to the deeplink
          window.location.href = deeplink;
        }

        hideLoader();
      } catch (error) {
        console.error("Error during validation:", error.status);

        if (error.status == 400) {
          setTokenExp(true);
          setTimeout(() => {
            navigate("/dashboard");
          }, 5000);
        }

        notifyError(error.response?.data?.message || "An error occurred");
        hideLoader();
      }
    };

    handleSubmit();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {tokenExp ? (
        <div style={{ width: "300px" }}> Token Expired. Create a new token</div>
      ) : (
        <div style={{ width: "300px" }}> Validating Token Please wait</div>
      )}
    </div>
  );
};

export default Validate;
