import React from "react";
import LoginLayout from "../../helper/loginLayout/loginLayout";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../components/CustomTextField";
import CustomButton from "../../components/CustomButton";
import { notifySuccess, notifyError } from "../../utils/toast";
import axios from "axios";
import { useLoader } from "../../context/LoaderContext";

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required("Name is required")
    .min(5, "Name must be at least 5 characters")
    .max(50, "Name must be at most 50 characters")
    .test(
      "no-only-spaces",
      "Name cannot be only spaces",
      (value) => !!value && value.trim().length > 0
    ),
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address format"
    )
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one numeric digit")
    .matches(
      /[^A-Za-z0-9]/,
      "Password must contain at least one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

const initialValues = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const SignUp = () => {
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const handleFormSubmit = async (values) => {
    // Define the payload based on the form values
    const payload = {
      name: values.name,
      email: values.email,
      password: values.password,
    };

    const timezoneOffset = -new Date().getTimezoneOffset();

    try {
      showLoader();
      // Make the POST request with Axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sign-up`,
        payload,
        {
          headers: {
            "client-secret": "44E536348B96148F35ABC9F14F8B6",
            lang: "en",
            "timezone-offset": timezoneOffset,
            version: "1.0",
          },
        }
      );

      // console.log('Sign-up successful:', response.data);
      // localStorage.setItem('accessToken', response.data.data.access_token)
      // localStorage.setItem('refreshToken', response.data.data.refresh_token)
      // localStorage.setItem('webUserId', response.data.data.web_user_id)
      notifySuccess("Account created successfully");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error during sign-up:", error);
      // You can also access error.response for more details if needed
      notifyError(error.response.data.message);
    } finally {
      hideLoader();
    }
  };
  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div>
      <LoginLayout>
        <div style={{ overflowY: "auto", marginTop: "30px" }}>
          <h1 style={{ textAlign: "center" }}>Virtual Intros</h1>

          <h2 style={{ textAlign: "center" }}>Create an account</h2>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
          >
            {({ errors, touched, isValid, dirty }) => (
              <Form>
                <Field
                  name="name"
                  component={CustomTextField}
                  label="Full name"
                  placeholder="Name"
                />
                <Field
                  name="email"
                  component={CustomTextField}
                  label="Email address"
                  placeholder="Email"
                />
                <Field
                  name="password"
                  component={CustomTextField}
                  label="Password"
                  placeholder="Password"
                  type="password"
                />
                <Field
                  name="confirmPassword"
                  component={CustomTextField}
                  label="Confirm password"
                  placeholder="Confirm Password"
                  type="password"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "30px",
                  }}
                >
                  <CustomButton
                    text="Get Started"
                    background="#355935"
                    type="submit"
                    width="160px"
                    disabled={!isValid || !dirty} // Disable until form is valid and dirty
                  />
                </div>
                <p style={{ textAlign: "center", paddingTop: "20px" }}>
                  I already have an account?{" "}
                  <span
                    onClick={handleLoginClick}
                    style={{ cursor: "pointer", fontWeight: "600" }}
                    role="button"
                    aria-label="Login"
                  >
                    Login
                  </span>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </LoginLayout>
    </div>
  );
};

export default SignUp;
