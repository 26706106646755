import React from "react";
import LoginLayout from "../../helper/loginLayout/loginLayout";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import CustomTextField from "../../components/CustomTextField";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utils/toast";
import axios from "axios";
import { useLoader } from "../../context/LoaderContext";

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address format")
    .required("Email is required"),
});

const ForgotPass = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoader();

  const handleFormSubmit = async (values) => {
    // Define the payload based on the form values
    const payload = {
      // name: values.name,
      email: values.email,
    };

    const timezoneOffset = -new Date().getTimezoneOffset();

    try {
      showLoader();
      // Make the POST request with Axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        payload,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            version: "1.0",
          },
        }
      );

      console.log("Sign-up successful:", response.data);
      sessionStorage.setItem("deepLinkEmail", values.email);
      notifySuccess("OTP sent successfully");
      navigate("/resend-link-successfully");
      hideLoader();
    } catch (error) {
      console.error("Error during sign-up:", error);
      // You can also access error.response for more details if needed
      notifyError(error.response.data.message);
      hideLoader();
    }
  };

  return (
    <div>
      <LoginLayout>
        <div>
          <h2 style={{ textAlign: "center" }}>Enter registererd email</h2>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
          >
            {({ errors, values, touched, isValid, dirty }) => (
              <Form>
                <Field
                  name="email"
                  component={CustomTextField}
                  label="Email address"
                  placeholder="Email"
                />

                <div
                  // className={styles.loginButtonContainer}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <CustomButton
                    text="Forgot Password"
                    background="#355935"
                    type="submit"
                    // className={styles.loginSubmit}
                    width={200}
                    disabled={!isValid || !dirty}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LoginLayout>
    </div>
  );
};

export default ForgotPass;
