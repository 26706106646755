import { useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import styles from "../../styles/changePassword.module.css";
import CustomTextField from "../../components/CustomTextField";
import CustomButton from "../../components/CustomButton";
import DasboardLayout from "../../helper/dashboardLayout/Dasboardlayout";
import { notifySuccess, notifyError } from "../../utils/toast";
import { useLoader } from "../../context/LoaderContext";
import axiosInstance from "../../axiosConfig";

const validationSchema = Yup.object({
  oldPassword: Yup.string().required("Old password is required"),
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .matches(/[A-Z]/, "At least one uppercase letter")
    .matches(/[a-z]/, "At least one lowercase letter")
    .matches(/[@$!%*?&#]/, "At least one symbol (@$!%*?&#)")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Both passwords must match")
    .required("Confirm Password is required"),
});

const ChangePassword = () => {
  const [passwordStrength, setPasswordStrength] = useState({
    minLength: false,
    upperCase: false,
    lowerCase: false,
    symbol: false,
  });

  const [passwordErrors, setPasswordErrors] = useState({
    minLength: "",
    upperCase: "",
    lowerCase: "",
    symbol: "",
  });

  const { showLoader, hideLoader } = useLoader();

  const handlePasswordChange = (e, setFieldValue) => {
    const { value } = e.target;

    const newStrength = {
      minLength: value.length >= 8,
      upperCase: /[A-Z]/.test(value),
      lowerCase: /[a-z]/.test(value),
      symbol: /[@$!%*?&#]/.test(value),
    };

    setPasswordStrength(newStrength);

    setPasswordErrors({
      minLength: newStrength.minLength ? "" : "Minimum 8 characters required",
      upperCase: newStrength.upperCase
        ? ""
        : "At least one uppercase letter required",
      lowerCase: newStrength.lowerCase
        ? ""
        : "At least one lowercase letter required",
      symbol: newStrength.symbol
        ? ""
        : "At least one symbol (@$!%*?&#) required",
    });

    setFieldValue("password", value);
  };

  const handleSubmit = async (values) => {
    // Define the payload based on the form values
    const payload = {
      // name: values.name,
      new_password: values.password,
      old_password: values.oldPassword,
    };

    const timezoneOffset = -new Date().getTimezoneOffset();

    const token = localStorage.getItem("accessToken");

    try {
      // Make the put request with Axios
      showLoader();
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/change-password`,
        payload,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            Authorization: `Bearer ${token}`,
            version: "1.0",
          },
        }
      );

      console.log("Sign-up successful:", response.data);

      notifySuccess("Password changes successfully");
      hideLoader();
      //   navigate('/dashboard');
    } catch (error) {
      console.error("Error during sign-up:", error);
      // You can also access error.response for more details if needed
      notifyError(error.response.data.message);
      hideLoader();
    }
  };

  return (
    <DasboardLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Create new password</h1>
        <p className={styles.changeNewPassText}>
          Your new password must be different than your most recent password.
        </p>
        <div
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <div className={styles.createNewPassInputCon}>
            <Formik
              initialValues={{
                oldPassword: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  {/* Old Password Field */}
                  <Field
                    name="oldPassword"
                    component={CustomTextField}
                    label="Old Password"
                    placeholder="Old Password"
                    type="password"
                  />

                  {/* New Password Field */}
                  <Field
                    name="password"
                    component={CustomTextField}
                    label="New Password"
                    placeholder="New Password"
                    type="password"
                    onChange={(e) => handlePasswordChange(e, setFieldValue)}
                  />

                  {/* Confirm New Password Field */}
                  <Field
                    name="confirmPassword"
                    component={CustomTextField}
                    label="Confirm New Password"
                    placeholder="Confirm New Password"
                    type="password"
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "30px",
                    }}
                  >
                    <CustomButton
                      text="Change password"
                      background="#355935"
                      type="submit"
                      className={styles.createNewPassButton}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </DasboardLayout>
  );
};

export default ChangePassword;
