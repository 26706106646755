import React from 'react'
import LoginLayout from '../../helper/loginLayout/loginLayout'

const ResendLinkSent = () => {
    const email = sessionStorage.getItem('deepLinkEmail')
  return (
   <LoginLayout>
    <div>
     <h2 style={{textAlign:'center'}}>Email Sent</h2>
    <div style={{fontSize:'18px'}}>A reset password link has been sent to </div>
    <span style={{textAlign:'center',fontWeight:'600', display:'block'}}>{email}</span>
   </div>
   </LoginLayout>
  )
}

export default ResendLinkSent