import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/logo.svg";
import play_logo from "../../assets/Mask Group 1.svg";
import apple_logo from "../../assets/app_store.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [language, setLanguage] = useState("en");
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleNavigation = (path, hash) => {
    navigate(path);
    setTimeout(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setMobileMenuOpen(false);
    }, 0);
  };

  const handleLanguageChange = (event) => {
    const newLang = event.target.value;
    setLanguage(newLang);
    i18n.changeLanguage(newLang); // Change the language in i18n
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  return (
    <header className="header-wrapper">
      <div className="App">
        <div className="header">
          <div className="logo">
            <img src={logo} alt="Virtual Intros Logo" />
          </div>

          {/* Hamburger Menu Button */}
          <button
            className="hamburger-menu"
            onClick={toggleMobileMenu}
            aria-label="Toggle navigation"
          >
            ☰
          </button>

          {/* Navigation Menu */}
          <nav className={`nav-menu ${isMobileMenuOpen ? "mobile-open" : ""}`}>
            <a onClick={() => handleNavigation("/")}>{t("home")}</a>
            <a onClick={() => handleNavigation("/", "#about")}>
              {t("about_us")}
            </a>
            <a onClick={() => handleNavigation("/", "#how-it-works")}>
              {t("how_it_works")}
            </a>
            <a href="#contact">{t("contact_us")}</a>
            <select
              value={language}
              onChange={handleLanguageChange}
              className="language-dropdown"
            >
              <option value="en">English</option>
              <option value="sp">Español</option>
              <option value="pe">Português</option>
              <option value="cf">Français</option>
            </select>
            <img
              className="header-play"
              src={play_logo}
              onClick={() => {
                setMobileMenuOpen(false);
                return window.open(
                  "https://play.google.com/store/apps/details?id=com.intros.virtual&pli=1",
                  "_blank"
                );
              }}
              alt="play store logo"
            />
            <img
              className="header-apple"
              src={apple_logo}
              onClick={() => {
                setMobileMenuOpen(false);
                return window.open(
                  "https://apps.apple.com/in/app/virtual-intros/id6621269996",
                  "_blank"
                );
              }}
              alt="App store logo"
            />
          </nav>
        </div>

        {/* Outlined Box with Text */}
        <div
          className="header-outlined-box"
          onClick={() => handleNavigation("/login")}
        >
          <div>
            <div style={{ cursor: "pointer" }}>{t("services")}</div>
          </div>
        </div>

        {/* Background for mobile menu */}
        {isMobileMenuOpen && (
          <div
            className="mobile-menu-background"
            onClick={toggleMobileMenu}
          ></div>
        )}
      </div>
    </header>
  );
};

export default Header;
