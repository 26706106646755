import React, { useState, useEffect, useRef } from "react";
import "./Working.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Frame1 from "../../assets/Frame 1.svg";
import Frame2 from "../../assets/Frame 2.svg";
import Frame3 from "../../assets/Frame 3.svg";
import Frame4 from "../../assets/Frame 4.svg";
import Frame5 from "../../assets/Frame 5.svg";
import Frame6 from "../../assets/Frame 6.svg";
import { useTranslation } from "react-i18next";

function Working() {
  const { t } = useTranslation()
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const PrevArrow = ({ onClick }) => (
    <div className="arrow prev-arrow" onClick={onClick}>
      &#9664; {/* Unicode for left arrow */}
    </div>
  );

  const NextArrow = ({ onClick }) => (
    <div className="arrow next-arrow" onClick={onClick}>
      &#9654; {/* Unicode for right arrow */}
    </div>
  );

  return (
    <>
      <div className="App">
        <h1 className="Working_h1">{t('how_it_works_title')}</h1>
        <div className="slider-container">
          {/* <h4>First Slider</h4> */}
          <Slider
            asNavFor={nav1}
            ref={(slider) => (sliderRef2 = slider)}
            slidesToShow={3} // Show 3 slides to give context to the centered one
            swipeToSlide={true}
            focusOnSelect={true}
            className="first-slider"
            centerMode={true}
            infinite={true}
            beforeChange={(oldIndex, newIndex) => setCurrentIndex(newIndex)}
            responsive={[
              {
                breakpoint: 1024, // Screen width of 1024px and below
                settings: {
                  slidesToShow: 2, // Show 2 slides at this screen width
                },
              },
              {
                breakpoint: 768, // Screen width of 768px and below
                settings: {
                  slidesToShow: 1, // Show 1 slide at this screen width
                },
              },
              {
                breakpoint: 480, // Screen width of 480px and below
                settings: {
                  slidesToShow: 1, // Show 1 slide at this screen width
                },
              },
            ]}
          // variableWidth={true}
          >
            <button
              className={`button_1 ${currentIndex === 0 ? "active" : "inactive"}`}
            >
              {t('step1_part1' )}
              {t('step1_part2')}
            </button>
            <button
              className={`button_1 ${currentIndex === 1 ? "active" : "inactive"}`}
            >
              {t('step2_part1')}
              {t('step2_part2')}
            </button>
            <button
              className={`button_1 ${currentIndex === 2 ? "active" : "inactive"}`}
            >
              {t('step3_part1')}
              {t('step3_part2')}
            </button>
            <button
              className={`button_1 ${currentIndex === 3 ? "active" : "inactive"}`}
            >
              {t('step4_part1')}
              {t('step4_part2')}
            </button>
            <button
              className={`button_1 ${currentIndex === 4 ? "active" : "inactive"}`}
            >
              {t('step5_part1')}
              {t('step5_part2')}
            </button>
            <button
              className={`button_1 ${currentIndex === 5 ? "active" : "inactive"}`}
            >
              {t('step6_part1')}
              {t('step6_part2')}
            </button>
          </Slider>
          {/* <h4>Second Slider</h4> */}

          <Slider
            asNavFor={nav2}
            ref={(slider) => (sliderRef1 = slider)}
            prevArrow={<PrevArrow />}
            nextArrow={<NextArrow />}
          >
            <div className="Img_1">
              <img src={Frame1} />
            </div>
            <div className="Img_1">
              <img src={Frame2} />
            </div>
            <div className="Img_1">
              <img src={Frame3} />
            </div>
            <div className="Img_1">
              <img src={Frame4} />
            </div>
            <div className="Img_1">
              <img src={Frame5} />
            </div>
            <div className="Img_1">
              <img src={Frame6} />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Working;
