// src/api/axiosInterceptor.ts
import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
});

// Request Interceptor (Optional, if you need to modify or log requests)
axiosInstance.interceptors.request.use(
  (config) => {
    console.log('Request Made:', config);
    // You can modify the request config before the request is sent
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
   
    return config;
  },
  (error) => {
    // Log request error
    console.error('Error in Request:', error);
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Log the full response here
    console.log('Response Received:', response);

    // Log only the response data
    console.log('Response Data:', response.data);

    // Return the response so the calling code can handle it
    return response;
  },
  async (error) => {
    // Log error details here
    console.error('Error Response:', error.response);

    console.log('inside error==> of interseptor', error.response)
    if (error.response) {

      // const auth = sessionStorage.getItem('auth')

      const refresh = localStorage.getItem('refreshToken')

      const timezoneOffset = -new Date().getTimezoneOffset();


      if (refresh && error.response.status == 401) {
        try {
              const result_2 = await axiosInstance
                  .post(`/refresh-token`, {
                      refresh_token: refresh,
                  },
                      {
                          headers: {
                              'client-secret': process.env.REACT_APP_CLIENT_SECRET,
                              'lang': 'en',
                              'timezone-offset': timezoneOffset,
                              'version': '1.0',
                          },
                      });
              const tokens = result_2.data?.data;
              console.log(tokens);
              sessionStorage.setItem('accessToken', tokens?.access_token);

              sessionStorage.setItem('refreshToken', tokens?.refresh_token);
              return await axiosInstance.request(error.config);
          } catch (err) {
              localStorage.clear();
              window.location.reload();
          }
      }

       // Handle 500 error code by clearing localStorage and navigating to login
    //    if (error.response.status === 500) {
    //     localStorage.clear();
    //     window.location.reload();
    //   }
      // Log specific response details like status and data
      console.error('Error Status:', error.response.status);
      console.error('Error Data:', error.response.data);
    } else {
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
