// import React from 'react';
// import './ValueSection.css';

// const ValueSection = () => {
//   return (
//     <section className="value-section">
//       <h2>The Value You Get</h2>
//       <div className="values">
//         <div className="value">
//           <h3>01. <br/><span>Individual Users</span></h3>
//           <p>The application provides individual users with the ability to connect and network with industry professionals, access tailored job recommendations, attend and host events, and showcase their professional brand. Users can also manage their preferences, control their visibility, and engage with content relevant to their interests, all while maintaining privacy and enhancing their career growth.</p>
//         </div>
//         <div className="value">
//           <h3>02. Company Users</h3>
//           <p>Manage and invite members to the company profile.</p>
//         </div>
//         {/* Add more values as needed */}
//       </div>
//     </section>
//   );
// };

// export default ValueSection;
import React from "react";
import "./ValueSection.css";
import { useTranslation } from "react-i18next";

const ValueSection = () => {
  const { t } = useTranslation()
  return (
    <div className="value-section-wrapper">
      <div className="App">
        <div className="value-section">
          <h2><b>{t('the_value_you_get')}</b></h2>
          <div className="value-cards">
            <div className="value-card value-card-1">
              <h3>01.</h3>
              <h4>{t('individual_users')}</h4>
              <p className="description">
                {t('individual_users_description')}
              </p>
            </div>
            <div className="value-card value-card-2">
              <h3>02.</h3>
              <h4>{t('company_users')}</h4>
              <p className="description">
                {t('company_users_description')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueSection;
