// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation files
import enTranslations from './locales/english.json';
import spTranslations from './locales/spanish.json';
import peTranslations from './locales/portuguese.json';
import cfTranslations from './locales/canadian.json';


i18n
    .use(initReactI18next) // Pass i18n to react-i18next
    .init({
        resources: {
            en: { translation: enTranslations },
            sp: { translation: spTranslations },
            pe: { translation: peTranslations },
            cf: { translation: cfTranslations },

        },
        lng: 'en', // Default language
        fallbackLng: 'en', // If a translation is missing, use English
        interpolation: {
            escapeValue: false, // React already escapes values
        },
    });

export default i18n;
