import React, { useEffect, useState } from "react";
import DasboardLayout from "../../helper/dashboardLayout/Dasboardlayout";
import CustomTable from "../../components/CustomTable";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { notifyError, notifySuccess } from "../../utils/toast";
import { useLoader } from "../../context/LoaderContext";
import axiosInstance from "../../axiosConfig";

const columns = [
  { id: "transaction_id", label: "Transaction id", width: "150px" },
  { id: "name", label: "Name", width: "100px" },
  { id: "app_name", label: "App name", width: "100px" },
  { id: "created_at", label: "Date", width: "100px" },
  { id: "duration", label: "Duration", width: "120px" },
  { id: "amount", label: "Price", width: "100px" },
];

const Transaction = () => {
  const [page, setPage] = useState(0);
  const { showLoader, hideLoader } = useLoader();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    const timezoneOffset = -new Date().getTimezoneOffset();
    const token = localStorage.getItem("accessToken");

    try {
      showLoader();
      const response = await axiosInstance.get(
        `${
          process.env.REACT_APP_API_URL
        }/transactions?page_size=${rowsPerPage}&page_no=${page + 1}`,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            Authorization: `Bearer ${token}`,
            version: "1.0",
          },
        }
      );

      setTotalCount(response?.data?.data.total);
      setData(response?.data?.data.transactions);
      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      notifyError(error.response.data.message);
      hideLoader();
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const transformedData = data?.map((item) => ({
    ...item,
    duration: `${item.duration} months`,
    amount: `$${item.amount}`,
    created_at: formatDate(item.created_at),
  }));

  const renderAction = (row) => (
    <CopyToClipboard
      text={row.deeplink.props.href}
      onCopy={() => notifySuccess("Link copied!")}
    >
      <IconButton color="primary">
        <ContentCopyIcon />
      </IconButton>
    </CopyToClipboard>
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <DasboardLayout>
      <CustomTable
        columns={columns}
        data={transformedData}
        // renderAction={renderAction}
        page={page}
        rowsPerPage={rowsPerPage}
        totalCount={totalCount}
        onPageChange={handlePageChange}
      />
    </DasboardLayout>
  );
};

export default Transaction;
