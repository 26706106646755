import React from "react";
import "./HeroSection.css";
import HeroImage from "../../assets/hero-image.png";
import { useTranslation } from "react-i18next";
import play_logo from "../../assets/Mobile_play_store.svg";
import apple_logo from "../../assets/Mobile_app_store.svg";
import play_logo1 from "../../assets/Mask Group 1.svg";
import apple_logo1 from "../../assets/app_store.svg";

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <section className="hero-section-wrapper">
      <div className="App">
        <div className="hero-section">
          <div className="hero-content">
            <h1 className="hh">
              <span className="hh1">{t("confidentially")}</span>
              {t("grow_your_connections")}
            </h1>
            <p className="hero-attend">{t("hero_attend")}</p>
            <p className="hero-virtual">{t("hero_virtual")}</p>
            <p className="hero-virtual">{t("hero_virtual2")}</p>
            <div style={{ display: "flex", gap: "5px" }}>
              <p className="hero-download">{t("hero_download")}</p>
              <div className="hero-small-button">
                <img
                  className="header-play"
                  src={play_logo1}
                  onClick={() => {
                    return window.open(
                      "https://play.google.com/store/apps/details?id=com.intros.virtual&pli=1",
                      "_blank"
                    );
                  }}
                  alt="play store logo"
                />
                <img
                  className="header-apple"
                  src={apple_logo1}
                  onClick={() => {
                    return window.open(
                      "https://apps.apple.com/in/app/virtual-intros/id6621269996",
                      "_blank"
                    );
                  }}
                  alt="App store logo"
                />
              </div>
            </div>
            {/* <div className="hero-buttons">
              <button className="hero-apple-button">
                <img src={appleImage} alt="Apple icon" />
                <div className="button-text">
                  <span>Download on the</span>
                  <span style={{fontSize:"18px"}}>App Store</span>
                </div>
              </button>
              <button className="hero-play-button">
                <img src={playImage} alt="Play icon" />
                <div className="button-text">
                  <span>Get it on</span>
                  <span style={{fontSize:"18px"}}>Play Store</span>
                </div>
              </button>
            </div> */}
            {/* <div className="hero-buttons">
              <img
                src="https://d3nw6jtllu5stf.cloudfront.net/logo/play_buttons.png"
                alt="Download Buttons"
                height="75"
              />
            </div> */}
            <div className="hero-button">
              <img
                className="Download-play-hero"
                src={play_logo}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.intros.virtual&pli=1",
                    "_blank"
                  )
                }
                alt="play store logo"
              />
              <img
                className="Download-play-hero"
                src={apple_logo}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/in/app/virtual-intros/id6621269996",
                    "_blank"
                  )
                }
                alt="App store logo"
              />{" "}
            </div>
          </div>
          <div className="hero-image-outer">
            <div className="hero-image-thumb">
              <img src={HeroImage} alt="Background-1" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
