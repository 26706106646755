import React, { useContext } from "react";
import LoginLayout from "../../helper/loginLayout/loginLayout";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import CustomTextField from "../../components/CustomTextField";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from "../../utils/toast";
import { AuthContext } from "../../context/AuthContext";
import { useLoader } from "../../context/LoaderContext";
import axiosInstance from "../../axiosConfig";
import logo from "../../assets/images/logo1.svg";

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address format")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = () => {
  const { showLoader, hideLoader } = useLoader();
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    // Define the payload based on the form values
    const payload = {
      // name: values.name,
      email: values.email,
      password: values.password,
    };

    const timezoneOffset = -new Date().getTimezoneOffset();

    try {
      // Make the POST request with Axios
      showLoader();
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/login`,
        payload,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            version: "1.0",
          },
        }
      );

      console.log("Sign-up successful:", response.data);
      localStorage.setItem("accessToken", response.data.data.access_token);
      localStorage.setItem("refreshToken", response.data.data.refresh_token);
      localStorage.setItem("webUserId", response.data.data.web_user_id);
      // localStorage.setItem('webUserEmail', values.email)
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("isVerified", "true");
      setIsAuthenticated(true);
      notifySuccess("Login successfully");
      navigate("/dashboard");
      hideLoader();
    } catch (error) {
      console.error("Error during sign-up:", error);
      hideLoader();
      // You can also access error.response for more details if needed
      notifyError(error.response.data.message);
    }
  };

  const handleForgotPass = () => {
    console.log("fogot clicked");
    navigate("/forgot-password");
  };

  const handleCreateAccount = () => {
    navigate("/signup");
  };

  return (
    <div>
      <LoginLayout>
        <div className="login-circle-container">
          <div className="login-circle">
            <img
              src={logo}
              alt="logo"
              width={100}
              height={100}
              className="login-icon "
            />
          </div>

          <h1 style={{ textAlign: "center" }}>Virtual Intros</h1>
          <p style={{ textAlign: "center" }}>
            <b>Add More Power to Your Event App:</b> Click to get started and
            make Virtual Intros a seamless part of your event experience!
          </p>
          <h2 style={{ textAlign: "center" }}>Login</h2>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
          >
            {({ errors, values, touched }) => (
              <Form>
                <Field
                  name="email"
                  component={CustomTextField}
                  label="Email address"
                  placeholder="Email"
                />
                <Field
                  name="password"
                  component={CustomTextField}
                  label="Password"
                  placeholder="Password"
                  type="password"
                />
                <div
                  // className={styles.loginForgotPassTextContainer}
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    paddingTop: "5px",
                  }}
                >
                  <p
                    style={{ fontWeight: "600", cursor: "pointer" }}
                    onClick={handleForgotPass}
                  >
                    Forgot password?
                  </p>
                </div>
                <div
                  // className={styles.loginButtonContainer}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <CustomButton
                    text="Login"
                    background="#355935"
                    type="submit"
                    // className={styles.loginSubmit}
                    width={200}
                  />
                </div>
              </Form>
            )}
          </Formik>

          <p
            // className={styles.loginBottomText}
            style={{ marginTop: "10px" }}
          >
            Don’t have an account?{" "}
            <span
              style={{ fontWeight: "600", cursor: "pointer" }}
              onClick={handleCreateAccount}
            >
              Create an account
            </span>
          </p>
        </div>
      </LoginLayout>
    </div>
  );
};

export default Login;
