import React from "react";
import LoginLayout from "../../helper/loginLayout/loginLayout";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import CustomTextField from "../../components/CustomTextField";
import CustomButton from "../../components/CustomButton";
import { useNavigate, useLocation } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utils/toast";
import axios from "axios";
import { useLoader } from "../../context/LoaderContext";

const validationSchema = Yup.object({
  Password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one numeric digit")
    .matches(
      /[^A-Za-z0-9]/,
      "Password must contain at least one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("Password")], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showLoader, hideLoader } = useLoader();

  // Retrieve the token parameter from the URL
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  console.log(token);

  const handleFormSubmit = async (values) => {
    const payload = {
      newPassword: values.Password,
      token: token, // Include the token in the payload
    };

    const timezoneOffset = -new Date().getTimezoneOffset();

    try {
      showLoader();
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        payload,
        {
          headers: {
            "client-secret": process.env.REACT_APP_CLIENT_SECRET,
            lang: "en",
            "timezone-offset": timezoneOffset,
            version: "1.0",
          },
        }
      );

      notifySuccess("Password Updated Successfully");
      navigate("/login");
    } catch (error) {
      console.error("Error during password reset:", error);
      notifyError(error.response?.data?.message || "An error occurred");
    } finally {
      hideLoader();
    }
  };

  return (
    <div>
      <LoginLayout>
        <div>
          <h2 style={{ textAlign: "center" }}>Enter new password</h2>
          <Formik
            initialValues={{ Password: "", confirmPassword: "" }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
          >
            {({ errors, values, touched, isValid, dirty }) => (
              <Form>
                <Field
                  name="Password"
                  component={CustomTextField}
                  label="Password"
                  placeholder="Password"
                  type="password"
                />
                <Field
                  name="confirmPassword"
                  component={CustomTextField}
                  label="Confirm password"
                  placeholder="Confirm Password"
                  type="password"
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <CustomButton
                    text="Reset Password"
                    background="#355935"
                    type="submit"
                    width={200}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LoginLayout>
    </div>
  );
};

export default ResetPassword;
