import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import CustomButton from "../CustomButton";

const LogoutModal = ({ open, onConfirm, onCancel }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      sx={{ textAlign: "center" }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(255, 255, 255, 0)', // Set proper opacity
          backdropFilter: 'blur(9px)', // Apply blur effect to backdrop
        },
      }}
      PaperProps={{
        sx: {
        //   backgroundColor: "#130731", 
          borderRadius: "15px", // Rounded corners for the modal
          padding: "20px", // Padding around the modal
        },
      }}
    >
      <DialogTitle sx={{  fontSize: '22px', fontWeight: '600', lineHeight: '26.82px', letterSpacing: '-0.02rem', fontFamily: 'var(--font-montserrat)' }}>
        Logout
      </DialogTitle>
      <DialogContent>
        <Typography sx={{  fontWeight: '500', fontSize: '14px', lineHeight: '20px', fontFamily: 'var(--font-montserrat)' }}>
          Are you sure you want to logout?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Typography sx={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: '20px' }}>
          <CustomButton
            text="Logout"
            width="250px"
            background="#355935"
            onClick={onConfirm}
          />
          <Typography
            onClick={onCancel}
            sx={{  cursor: "pointer", fontWeight: '600', fontSize: '18px', lineHeight: '27px', fontFamily: 'var(--font-montserrat)' }}
          >
            No
          </Typography>
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
