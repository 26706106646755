import React, { useEffect } from 'react';
import DasboardLayout from '../../helper/dashboardLayout/Dasboardlayout';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notifySuccess } from '../../utils/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


import 'react-toastify/dist/ReactToastify.css';

const PaymentSuccess = () => {
  const deeplink = sessionStorage.getItem('deeplink');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const session_id = queryParams.get('session_id');

  const navigate = useNavigate()

console.log('session_id',session_id)

  useEffect(()=>{
    if(!session_id || !deeplink){
      navigate('/dashboard')
    }
  },[session_id,deeplink])


  return (
    <DasboardLayout>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign:'center',wordBreak:'break-all' }}>
        <div style={{fontSize:'16px',fontWeight:'700',paddingBottom:'20px'}}>Payment Successful</div>
        <p style={{ maxWidth: '500px' }}>{deeplink}</p>
        <CopyToClipboard text={deeplink} onCopy={() => {
          notifySuccess('copied');
        }}>
          <div style={{display:'flex',gap:'2px',alignItems:'baseline',cursor:'pointer'}}>
          <button style={{ marginTop: '10px', fontWeight:'600', color:'#F37B2F' }}>Copy Deeplink</button>
          <ContentCopyIcon/>
          </div>
        </CopyToClipboard>
      </div>
    </DasboardLayout>
  );
};

export default PaymentSuccess;
