// toast.js
import { toast } from 'react-toastify';

export const notifySuccess = (message) => {
  toast.success(message, {
    position: toast.right,
  });
};

export const notifyError = (message) => {
  console.log('message',message)
  toast.error(message, {
    position: toast.right,
  });
};

export const notifyInfo = (message) => {
  toast.info(message, {
    position: toast.right,
  });
};
