import React from "react";
import { Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logoImg from "../../assets/images/logo.svg";

const Sidebar = () => {
  console.log("window.location.href==>", window.location.href);
  const isActive = (path) => {
    return window.location.href.includes(path) ? "active" : "";
  };

  return (
    <div className="sidebar-wrapper">
      <div className="brand-link">
        <Image src={logoImg} alt="auth bg" className="image-fit auth-bg" />
      </div>
      <ul className="sidebar-menu">
        <li>
          <NavLink
            to="/dashboard"
            className={`nav-item ${isActive("/dashboard")}`}
          >
            <span className="nav-text">Purchase a deep link</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/MyDeepLink"
            className={`nav-item ${isActive("/MyDeepLink")}`}
          >
            <span className="nav-text">My deep links</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/transaction"
            className={`nav-item ${isActive("/transaction")}`}
          >
            <span className="nav-text">Transaction history</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
